.bulk {
  &-main {
    padding: 0.75em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }

  &-btn {
    display: flex;
    align-items: center;
    padding: 0.25em 1em;
    column-gap: 0.5em;
    font-size: 1rem;
  }

  &-modal {
    border-radius: 1em;

    &--body {
      height: 45vh;
      border-radius: 0.75em;
    }
  }
}

.upload_label {
  padding: 0.75em 1.5em;
  border-radius: 0.5em;
  background-color: #265cf6;
  color: white;
  width: max-content;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5em;
  cursor: pointer;
}

#bulk-upload-input {
  display: none;
}

.uploadDocCardStyle {
  padding: 0.75em 1em;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #dfe0eb;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mapBatchWorkOrderHead {
  color: var(--Gray-800, #1d2939);
  font-family: 'font-bold', system-ui;
  font-size: 1.28571rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.28571rem;
}

.headerCardPadding {
  padding: 16px 24px;
}

.bodyCardPadding {
  padding: 24px !important;
}

.flex-end {
  justify-content: flex-end;
}
.flex-start {
  justify-content: flex-start;
}

.bulk_filters {
  display: flex;
  justify-content: flex-start;
  align-items: last baseline;
  column-gap: 1rem;
}

.bulk-delivery.nav-tabs .nav-item.nav-link.active {
  padding: 8px 15px !important;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 2.5px solid #005dff !important;
  color: #000 !important;
  background-color: transparent !important;
  margin: 0 !important;
  margin-bottom: -2px !important;
  font-size: 16px !important;
}

.bulk-delivery.nav-tabs .nav-item.nav-link {
  padding: 8px 15px !important;
  border: 0 !important;
  margin: 0 !important;
  font-size: 16px !important;
}

.bulk-delivery {
  border-bottom: 2px solid #e8e8e8 !important;
}

.batch_id--infoCard {
  background-color: #f5fbff;
  display: grid;
  column-gap: 0.75rem;
  grid-template-columns: repeat(4, 1fr);
  .infoSection {
    row-gap: 0.25rem;
    &:not(:last-child) {
      border-right: 1.5px solid #d0d5dd;
    }
  }
}

.stepper {
  width: 100%;
  height: 60%;
  position: relative;

  &-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-image {
    margin-bottom: 0.75rem;
  }

  &-connector {
    &-1,
    &-2,
    &-3 {
      position: absolute;
      width: 32%;
      height: 2px;
      background-color: #d0d5dd;
      top: 8.5vh;

      &[data-active='true'] {
        background-color: #1570ef;
      }
    }

    &-1 {
      left: 20%;
    }

    &-2 {
      right: 15%;
    }
  }
}

// * TRANSACTION SCREENS * //
.transaction {
  &-header {
    display: flex;
    justify-content: space-between;
    padding: 1.2rem 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  &-body {
    padding: 1rem;
    display: grid;
    column-gap: 1rem;
    grid-template-columns: 0.65fr 1fr;
  }

  &-field {
    border: 1px solid #f1f1f1;
    border-radius: 5px;

    h6 {
      padding: 0.75rem;
      background-color: #f5f5f5;
      text-transform: uppercase;
    }
  }

  &-form {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.5rem 0 0.5rem 0;
    }
  }

  &-grid {
    &__card {
      margin-bottom: 2rem;
      display: flex;
      gap: 1rem;
    }
  }

  &-preview {
    display: grid;
    column-gap: 10px;
    grid-template-columns: 14rem auto;
    height: 79vh;
    overflow-y: scroll;

    & > div {
      background-color: #f5f5f5;
      height: inherit;
    }

    &__doclist {
      display: flex;
      flex-direction: column;

      .docSet {
        display: grid;
      }
    }

    .carousel {
      height: 100%;
      display: flex;
      row-gap: 10px;
      justify-content: space-between;
      flex-direction: column;

      &-header {
        flex: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;

        &__btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &-body {
        flex: 1;
        height: 62vh;

        &__frame {
          width: 100%;
          height: 100%;
        }

        &__image {
          height: 100%;

          img {
            width: 100%;
            height: inherit;
            display: block;
            margin-inline: auto;
            object-fit: contain;
          }
        }
      }

      &-footer {
        flex: 0;
        width: 100%;
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
      }

      &-indicator {
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 50% !important;
        background-color: #8190a7;

        &[data-change='true'] {
          background-color: #6ea3ff;
          outline: 1.5px solid #6ea3ff;
          outline-offset: 2px;
        }
      }
    }
  }
}
