// Styles
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
.App-alert {
  position: fixed;
  right: 1rem;
  top: 1rem;
  z-index: 60000;
}

.App-alert > * + * {
  margin-top: 1rem;
}
