@import 'src/scss/variables';
.details-filter {
  h6 {
    color: #000000;
    font-size: 1.143rem;
    font-family: $headings-font-family;
  }

  .label {
    color: $label-gray;
    font-family: $font-family-base;
    font-size: 0.8571rem;
  }
}

.tab-content {
  background-color: transparent;
}

.error-style {
  margin-top: -9px;
  margin-bottom: 0px;
  font-size: 80%;
  color: #ff7272;
}

.nav-pills {
  .nav-link.active {
    background-color: lightgrey;
    color: black;
  }
  .nav-link {
    color: black;
  }
}

.a {
  color: black;
}

.documentBtn {
  background-color: '#DEE6FE';
  color: '#247CFA';
  text-overflow: 'ellipsis';
  overflow: 'hidden';
  white-space: 'no-wrap';
}

.button_section {
  position: relative;
  width: 95px;
  margin-right: 10px;
}
input[type='file']::-webkit-file-upload-button {
  height: 34px;
}
.hide_file {
  width: 95px;
  position: absolute;
  z-index: 1000;
  opacity: 0;
}
