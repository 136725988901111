// .btn-light{width: 102px;}
.work-order-items-edit {
  tr {
    td {
      select.form-control {
        padding: 0 !important;
      }
      select[disabled] {
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}
.allocateTargetErrorText {
  color: #ff0000;
  margin-bottom: 0px;
}
