.verticalSeperator {
  color: rgba(165, 166, 246, 0.41);
}

.dataSpacing {
  padding-left: 1em;
  padding-right: 0.5em;
}

.tableHeader {
  height: 43px;
  align-items: center;
  margin: 0 0 46px 0px;
  padding: 0 20px;
  background-color: rgba(165, 166, 246, 0.41);
}
.tableData {
  height: 63px;
  align-items: center;
  margin: 40px 0px 5px 0;
  padding: 0 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
}
.sortImage {
  height: 20px;
  width: 20px;
  margin-left: 2em;
  margin-right: 0.5em;
}
.workAreaTable {
  overflow: scroll;
}

// ####################### Modal Styling ################################

.modalHeader {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-left: -10px;
}

.freeWorkOrderQuantityModal {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

.freeWorkOrderQuantityModalBodyHeader {
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: #000;
  margin-top: -18px;
}

.headerBrandNameLine {
  color: #979797;
  margin-left: 3px;
  margin-right: 3px;
}

.woCreationStyling {
  color: #979797;
  margin-bottom: 0px;
}

.vendorName {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #000;
  margin-top: 15px;
  margin-left: -10px;
}

.woDetailsRow {
  background-color: rgba(250, 250, 250, 0.6);
  border: 1px solid rgba(223, 222, 222, 0.5);
}

.cardFooter {
  border-top: 0px;
  margin-top: 10px;
}

.cancelButton {
  margin-right: 8px;
  border: 1px solid #004acc;
  color: #004acc;
}

.buttonDivStyle {
  display: flex;
  justify-content: flex-end;
}

//################################ DraftWO View/Edit ##############################################
.workOrderId {
  margin-top: 3px;
  font-size: 16px;
  font-weight: 600;
  color: #252733;
}

.workOrderId .draftWOLine {
  font-weight: normal;
  color: #979797;
}

.workOrderId .itemAndState {
  font-weight: normal;
}

.plannedViewEditSecondHeader {
  background-color: #e4ebf8;
  margin-left: 1px;
  margin-right: 1px;
  height: 75px;
  margin-top: 15px;
}

.plannedViewEditSecondHeaderText {
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0px;
}

.plannedViewEditSecondHeaderButtonSection {
  display: flex;
  justify-content: flex-end;
}

.brandwisePlannedEditViewHeaderCardHeader {
  font-size: 14px;
  color: #80807f;
  margin-bottom: 5px;
}

.brandwisePlannedEditViewHeaderCardValue {
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
}

.brandwisePlannedEditViewHeaderCardHorizontalLine {
  width: 2px;
  height: 50px;
  flex-grow: 0;
  margin-left: 15px;
  background-color: rgba(223, 222, 222, 0.5);
}

.plannedViewEditHeaderSection {
  display: flex;
  justify-content: space-between;
}

.brandwisePlannedEditViewHeaderCardTwoHeaderHorizontalLine {
  width: 1px;
  height: 55px;
  flex-grow: 0;
  margin: 1px 7.3px 2px 29.6px;
  background-color: rgba(165, 166, 246, 0.2);
}

.brandwisePlannedEditViewBodyDeliveriesText {
  font-size: 16px;
  font-weight: 600;
}

.brandwisePlannedEditViewBodyFilterHeader {
  font-size: 14px;
}

.brandwisePlannedEditViewBodyButtonStyle {
  color: #005dff;
  border: 1px solid #005dff;
  padding: 10px;
  font-family: 5px;
}

.brandwisePlannedEditViewHeaderFreeWOQuantityButtonStyleEnabled {
  color: #005dff;
  border: 1px solid #005dff;
  font-size: 16px;
  background-color: #fff;
  padding: 10px;
}

.brandwisePlannedEditViewHeaderFreeWOQuantityButtonStyleDisabled {
  color: #808080;
  border: 1px solid #808080;
  font-size: 16px;
  background-color: #fff;
  padding: 10px;
}

.brandwisePlannedEditViewBodyButtonDownloadIconStyle {
  width: 17px;
  margin: 3px;
  cursor: 'pointer';
}

.freeWorkOrderQuantityModalBodyFormSection {
  width: 737px;
  height: 136px;
  margin: 36px 2px 30px 0;
  padding: 17px 147px 14.5px 20px;
  border: solid 1px rgba(223, 222, 222, 0.5);
  background-color: #fafaff;
}

.freeWorkOrderQuantityModalBodyWODetailsText {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.replanningChangeAllocationHeaderText {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.replanningChangeAllocationSecondHeaderText {
  font-size: 14px;
  color: #80807f;
}

.replanningChangeAllocationSecondHeaderValue {
  font-size: 16px;
  color: #000;
}

.replanningChangeAllocationThirdHeaderSection {
  height: 100px;
}

.freeWOQuantityImage {
  width: 20px;
  margin-right: 3px;
}

.woAllocationCardSection {
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tableNoDataText {
  font-size: 14px;
  font-weight: 600;
}

.woAllocationTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.woAllocationDeliveriesTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.woChangeBrandAllocationTableSection {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.noDataSelectedFinancialYear {
  margin-top: 130px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.planning-form-row {
  margin: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  word-wrap: normal;
}

.planning-form-row-small {
  flex-grow: 0.2;
  flex-shrink: 0;
  flex-basis: 15%;
  max-width: 15%;
}

.planning-form-row-med {
  flex-grow: 1.5;
  flex-shrink: 1;
  flex-basis: 15%;
  max-width: 20%;
}

.planning-form-row-large {
  flex-grow: 2;
  flex-shrink: 2;
  flex-basis: 15%;
  max-width: 20%;
}
